/* eslint-disable */
import './assets/css/styles.css'; 
import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { history, Role } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute } from './components';
import HomePage from './views/HomePage';
import LoginPage from './views/LoginPage';
import LandingPage from './views/LandingPage'

import ProfilePage from './views/profile/ProfilePage'
import UsersListPage from './views/users/UsersListPage'
import UserCreatePage from './views/users/UserCreatePage';
import UserUpdatePage from './views/users/UserUpdatePage';
import ClientUpdatePage from './views/users/ClientUpdatePage';
import SellersListPage from './views/users/SellersListPage'
import SellerCreatePage from './views/users/SellerCreatePage';
import SellerUpdatePage from './views/users/SellerUpdatePage';

// Trabajadores 
import OperatorCreatePage from './views/workers/OperatorCreatePage';
import OperatorUpdatePage from './views/workers/OperatorUpdatePage';
import OperatorListPage from './views/workers/OperatorListPage';

//Sucursal
import AgencyListPage from './views/agency/AgencyList';
import AgencyCreatePage from './views/agency/AgencyCreate';
import AgencyUpdatePage from './views/agency/AgencyUpdate';
import AgencyCloseHistoryPage from './views/agency/agencyCloseHistory';

//Catalogo
import ProductListPage from './views/product/ProductList';
import ProductCreatePage from './views/product/ProductCreate';
import ProductUpdatePage from './views/product/ProductUpdate';
import ProductListHistoryPage from './views/product/ProductHistory';
import CombosListPage from './views/product/CombosList';
import CombosCreatePage from './views/product/CombosCreate';
import CombosUpdatePage from './views/product/CombosUpdate';
import CombosRecordPage from './views/product/CombosRecord';

//Inventario
import InventoryCreatePage from './views/inventory/InventoryCreate';
import InventoryReweighPage from './views/inventory/InventoryReweigh';
import InventoryReadjustmentPage from './views/inventory/inventoryReadjustment';
import InventoryResetPage from './views/inventory/inventoryReset';
import InventoryEntryHistoryPage from './views/reports/InventoryEntryHistory';

//Ventas
import SalesListUserPage from './views/sales/SalesListUser';
import SalesListManagerPage from './views/sales/SalesListManager';
import SalesListDailyPage from './views/sales/SalesListDaily';
import SalesCreatePage from './views/sales/SalesCreate';
import SalesCreateOfflinePage from './views/sales/SalesCreateOffline';
import SalesCombosChartPage from './views/sales/SalesCombosChart';

//Televentas
import TelesalesCommissionsReportPage from './views/telesales/TelesalesCommissionsReport';
import TelesalesListPage from './views/telesales/TelesalesList';
import TelesalesPendingPaymentsPage from './views/telesales/TelesalesPendingPayments';

//Monedas
import CoinListPage from './views/coin/CoinList';
import CoinCreatePage from './views/coin/CoinCreate';
//Terminales
import TerminalListPage from './views/terminal/TerminalList';
import TerminalCreatePage from './views/terminal/TerminalCreate';
import TerminalUpdatePage from './views/terminal/TerminalUpdate';
import TerminalReportPage from './views/terminal/TerminalReport';

//reportes
import InventorySellPage from './views/reports/inventorySell';
import InventoryOfferPage from './views/reports/inventoryOffers';
import InventoryHistoryPage from './views/reports/inventoryHistory';
import InventoryListPage from './views/reports/InventoryList';
import InventoryReportPage from './views/reports/inventoryReport';
import BalanceReportPage from './views/reports/balanceReport';
import InventoryReportDailyPage from './views/reports/inventoryReportDaily';
import CommissionsReportPage from './views/reports/commissionsReport';
import PaymentMethodsPage from './views/reports/PaymentMethods';
import PaymentMethodsHistoryPage from './views/reports/PaymentMethodsHistory'; 
import PaymentMethodsGeneralReportPage from './views/reports/PaymentMethodsGeneralReport';
import DepartureListPage from './views/reports/departureList';
import InventoryReportPlusPage from './views/reports/inventoryReportPlus';
import InventoryAdjustmentHistoryPage from './views/reports/inventoryAdjustmentHistory';
import CronHistoryPage from './views/reports/cronHistory';
import OperatorsPerformancePage from './views/reports/operatorsPerformance';
import CashiersPerformancePage from './views/reports/cashiersPerformance';
//Salidas por degustación, autoconsumo o donación
import DeparturePage from './views/departures/departureCreate';

//ofertas
import OfferListPage from './views/offer/OfferList';
import OfferCreatePage from './views/offer/OfferCreate';
//Reporte de ofertas historial
import OfferReportPage from './views/reports/offerReport';

//Caja
import BoxCreatePage from './views/box/BoxCreate';
import BoxWithdrawalPage from './views/box/BoxWithdrawal';
import BoxClosePage from './views/box/BoxClose';
import BoxCorrectionPage from './views/box/boxCorrection';
import BoxHistoryPage from './views/box/boxHistory';
import BoxAddPage from './views/box/BoxAdd';

//Resguardo
import ResguardAddPage from './views/resguard/ResguardAdd';
import ResguardReportPage from './views/resguard/ResguardReport';
import ResguardWithdrawalPage from './views/resguard/ResguardWithdrawal';
import ResguardHistoryPage from './views/resguard/resguardHistory';
import WithdrawalConfirmPage from './views/resguard/WithdrawalConfirm';
import WithdrawalReportPage from './views/resguard/WithdrawalReport';


//Reporte de caja
import BoxReportPage from './views/reports/BoxReport';
import BoxCloseReportPage from './views/reports/boxCloseReport';

//clientes
import ClientListPage from './views/reports/clientList';


//  Ventas al mayor 
import WholesaleCreatePage from './views/wholesales/WholesaleCreate';
import CreditPaymentPage from './views/wholesales/CreditPayment';
import PendingPaymentsPage from './views/wholesales/PendingPayments';
import WholesaleClientListPage from './views/reports/wholesaleClientList';
import WholesaleClientUpdatePage from './views/users/WholesaleClientUpdatePage';
import WholesaleClientRegisterPage from './views/users/WholesaleClientRegisterPage';
import PaymentMethodsChartPage from './views/reports/PaymentMethodsChart';

// Pedidos
import OrderHistoryPage from './views/order/OrderHistory';
import OrderHelperPage from './views/order/OrderHelper';

// Activos
import AssetsListPage from './views/assets/AssetsListPage';
import AssetsCreatePage from './views/assets/AssetsCreatePage';
import AssetsUpdatePage from './views/assets/AssetsUpdatePage';
import AssetsRecordPage from './views/assets/AssetsRecordPage';
import AssetsDumpListPage from './views/assets/AssetsDumpListPage';

// Codigos de autorizacion 
import AuthorizationCodeCreatePage from './views/authorizationCode/AuthorizationCodeCreatePage';
import AuthorizationCodesPage from './views/authorizationCode/AuthorizationCodesPage';

// Lista de Suministros
import MiscellaneousListPage from './views/miscellaneous/MiscellaneousList';
import MiscellaneousCreatePage from './views/miscellaneous/MiscellaneousCreate';
import MiscellaneousUpdatePage from './views/miscellaneous/MiscellaneousUpdate';
import MiscellaneousListHistoryPage from './views/miscellaneous/MiscellaneousHistory';
import MiscellaneousInventoryCreatePage from './views/miscellaneous/MiscellaneousInventoryCreate';
import InventoryMiscellaneousReadjustment from './views/miscellaneous/InventoryMiscellaneousReadjustment';
import DepartureMiscellaneousPage from './views/miscellaneous/MiscellaneouDeparturesCreate';
import InventoryMiscellaneousHistoryPage from './views/miscellaneous/inventoryHistory';
import InventoryMiscellaneousReportPage from './views/miscellaneous/inventoryReport';

//import InventoryMiscellaneousReportPage from './views/miscellaneous/MiscellaneousInventoryReport';

function App() {
    //const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={LoginPage} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <PrivateRoute path="/home" component={HomePage} />

                {/* Usuarios */}
                <PrivateRoute path="/users" roles={[Role.Admin, Role.Supervisor]} component={UsersListPage} />
                <PrivateRoute path="/register-user" roles={[Role.Admin]} component={UserCreatePage} />
                <PrivateRoute path="/update-user" roles={[Role.Admin]} component={UserUpdatePage} />
                <PrivateRoute path="/update-client" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={ClientUpdatePage} />
                <PrivateRoute path="/update-wholesale-client" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={WholesaleClientUpdatePage} />
                <PrivateRoute path="/register-wholesale-client" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={WholesaleClientRegisterPage} />
                <PrivateRoute path="/sellers" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={SellersListPage} />
                <PrivateRoute path="/register-seller" roles={[Role.Admin]} component={SellerCreatePage} />
                <PrivateRoute path="/update-seller" roles={[Role.Admin]} component={SellerUpdatePage} />

                {/* Trabajadores */}
                <PrivateRoute path="/operators" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={OperatorListPage} />
                <PrivateRoute path="/register-operator" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={OperatorCreatePage} />
                <PrivateRoute path="/update-operator" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={OperatorUpdatePage} />

                {/* Sucursales */}
                <PrivateRoute path="/agency" roles={[Role.Admin, Role.Supervisor]} component={AgencyListPage} />
                <PrivateRoute path="/register-agency" roles={[Role.Admin]} component={AgencyCreatePage} />
                <PrivateRoute path="/update-agency" roles={[Role.Admin]} component={AgencyUpdatePage} />
                <PrivateRoute path="/agency-close-history" roles={[Role.Admin, Role.Supervisor]} component={AgencyCloseHistoryPage} />

                {/* Productos */}
                <PrivateRoute path="/product" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Cashier, Role.Collector, Role.Auditor, Role.Telesales]} component={ProductListPage} />
                <PrivateRoute path="/register-product" roles={[Role.Admin]} component={ProductCreatePage} />
                <PrivateRoute path="/update-product" roles={[Role.Admin]} component={ProductUpdatePage} />
                <PrivateRoute path="/product-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Telesales]} component={ProductListHistoryPage} />
                <PrivateRoute path="/combos" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Cashier, Role.Collector, Role.Auditor, Role.Telesales]} component={CombosListPage} />
                <PrivateRoute path="/create-combo" roles={[Role.Admin]} component={CombosCreatePage} />
                <PrivateRoute path="/update-combo" roles={[Role.Admin]} component={CombosUpdatePage} />
                <PrivateRoute path="/record-combo" roles={[Role.Admin]} component={CombosRecordPage} />
                
                {/* Inventario */}
                <PrivateRoute path="/inventory" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Cashier, Role.Collector, Role.Auditor, Role.Telesales]} component={InventoryListPage} />
                <PrivateRoute path="/register-inventory" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryCreatePage} />
                <PrivateRoute path="/inventory-reweigh" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryReweighPage} />
                <PrivateRoute path="/readjustment" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector]} component={InventoryReadjustmentPage} />
                <PrivateRoute path="/inventory-reset" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryResetPage} />
                <PrivateRoute path="/inventory-entry-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor, Role.Telesales]} component={InventoryEntryHistoryPage} />

                {/* Suministros */}
                <PrivateRoute path="/miscellaneous" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector, Role.Auditor, Role.suplyRole]} component={MiscellaneousListPage} />
                <PrivateRoute path="/register-miscellaneous" roles={[Role.Admin, Role.Supervisor, Role.suplyRole]} component={MiscellaneousCreatePage} />
                <PrivateRoute path="/update-miscellaneous" roles={[Role.Admin, Role.Supervisor, Role.suplyRole]} component={MiscellaneousUpdatePage} />
                <PrivateRoute path="/miscellaneous-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector, Role.Auditor, Role.suplyRole]} component={MiscellaneousListHistoryPage} />
                <PrivateRoute path="/register-miscellaneous-inventory" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Cashier, Role.Collector, Role.Auditor, Role.suplyRole]} component={MiscellaneousInventoryCreatePage} />
                <PrivateRoute path="/readjustment-miscellaneous" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector, Role.suplyRole]} component={InventoryMiscellaneousReadjustment} />
                <PrivateRoute path="/departure-miscellaneous" roles={[Role.Admin, Role.Manager, Role.suplyRole]} component={DepartureMiscellaneousPage} />
                <PrivateRoute path="/inventory-miscellaneous-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor, Role.suplyRole]} component={InventoryMiscellaneousHistoryPage} />
                <PrivateRoute path="/inventory-miscellaneous-report" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor, Role.suplyRole]} component={InventoryMiscellaneousReportPage} />

                {/* Ventas */}
                <PrivateRoute path="/sales" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector, Role.Auditor]} component={SalesListManagerPage} />
                <PrivateRoute path="/offline-sales" roles={[ Role.Cashier, Role.Admin, Role.Manager]} component={SalesCreateOfflinePage} />
                <PrivateRoute path="/sales-daily" roles={[Role.Cashier]} component={SalesListDailyPage} />
                <PrivateRoute path="/register-sale" roles={[ Role.Cashier, Role.Manager]} component={SalesCreatePage} />
                <PrivateRoute path="/sales-user" roles={[ Role.Cashier,Role.Admin, Role.Manager]} component={SalesListUserPage} />
                <PrivateRoute path="/sales-combos-chart" roles={[ Role.Admin, Role.Manager]} component={SalesCombosChartPage} />

                {/* Ventas al mayor */}
                <PrivateRoute path="/register-wholesale" roles={[ Role.Cashier, Role.Admin, Role.Manager]} component={WholesaleCreatePage} />
                <PrivateRoute path="/credit-payment" roles={[ Role.Cashier, Role.Admin, Role.Manager]} component={CreditPaymentPage} />
                <PrivateRoute path="/pending-payments" roles={[ Role.Cashier, Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Telesales]} component={PendingPaymentsPage} />
                
                {/* Televentas */}
                <PrivateRoute path="/telesales" roles={[ Role.Admin, Role.Supervisor, Role.Telesales]} component={TelesalesListPage} />
                <PrivateRoute path="/telesales-commissions-report" roles={[ Role.Cashier, Role.Admin, Role.Telesales]} component={TelesalesCommissionsReportPage} />
                <PrivateRoute path="/telesales-pending-payments" roles={[ Role.Admin, Role.Telesales]} component={TelesalesPendingPaymentsPage} />
                
                {/* Monedas */}
                <PrivateRoute path="/coin" roles={[Role.Admin, Role.Supervisor]} component={CoinListPage} />
                <PrivateRoute path="/register-coin" roles={[ Role.Admin]} component={CoinCreatePage} />

                {/* Terminales */}
                <PrivateRoute path="/terminal" roles={[Role.Admin, Role.Supervisor, Role.Collector]} component={TerminalListPage} />
                <PrivateRoute path="/register-terminal" roles={[Role.Admin]} component={TerminalCreatePage} />
                <PrivateRoute path="/update-terminal" roles={[Role.Admin]} component={TerminalUpdatePage} />
                <PrivateRoute path="/terminal-reports" roles={[Role.Admin]} component={TerminalReportPage} />

                {/* Salidas por degustación, autoconsumo o donación */}
                <PrivateRoute path="/departure" roles={[Role.Admin, Role.Manager]} component={DeparturePage} />

                {/* Ofertas */}
                <PrivateRoute path="/offer" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Collector, Role.Auditor, Role.Telesales]} component={OfferListPage} />
                <PrivateRoute path="/create-offer" roles={[Role.Admin]} component={OfferCreatePage} />

                {/* Caja */}
                <PrivateRoute path="/box-opening" roles={[Role.Admin]} component={BoxCreatePage} />
                <PrivateRoute path="/box-withdrawal" roles={[Role.Manager,Role.Admin]} component={BoxWithdrawalPage} />
                <PrivateRoute path="/box-close" roles={[Role.Admin, Role.Manager, Role.Supervisor]} component={BoxClosePage} />
                <PrivateRoute path="/box-correction" roles={[Role.Admin, Role.Collector]} component={BoxCorrectionPage} />
                <PrivateRoute path="/box-history" roles={[Role.Admin, Role.Manager, Role.Collector,Role.Auditor, Role.Supervisor]} component={BoxHistoryPage} />
                <PrivateRoute path="/box-add" roles={[Role.Admin, Role.Manager, Role.Collector]} component={BoxAddPage} />
                
                {/* Resguardo */}
                <PrivateRoute path="/resguard-add" roles={[ Role.Manager]} component={ResguardAddPage} />
                <PrivateRoute path="/resguard-report" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={ResguardReportPage} />
                <PrivateRoute path="/resguard-withdrawal" roles={[ Role.Manager]} component={ResguardWithdrawalPage} />
                <PrivateRoute path="/resguard-confirm-withdrawal" roles={[Role.Admin]} component={WithdrawalConfirmPage} />
                <PrivateRoute path="/withdrawal-report" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={WithdrawalReportPage} />
                <PrivateRoute path="/resguard-history" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={ResguardHistoryPage} />


                {/* Ordenes */}
                <PrivateRoute path="/order-history" roles={[ Role.Admin, Role.Auditor, Role.Manager, Role.Collector, Role.Supervisor]} component={OrderHistoryPage} />
                <PrivateRoute path="/order-helper" roles={[ Role.Admin, Role.Auditor, Role.Manager, Role.Collector, Role.Supervisor]} component={OrderHelperPage} />


                {/* Reportes */}
                <PrivateRoute path="/inventory-sell" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor]} component={InventorySellPage} />
                <PrivateRoute path="/inventory-history" roles={[Role.Admin, Role.Supervisor, Role.Auditor]} component={InventoryHistoryPage} />
                <PrivateRoute path="/inventory-report" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor]} component={InventoryReportPage} />
                <PrivateRoute path="/balance-report" roles={[Role.Admin, Role.Manager]} component={BalanceReportPage} />
                <PrivateRoute path="/inventory-report-daily" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryReportDailyPage} />
                <PrivateRoute path="/commissions-report" roles={[Role.Admin, Role.Collector, Role.Auditor, Role.Supervisor]} component={CommissionsReportPage} />
                <PrivateRoute path="/payment-methods-report" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={PaymentMethodsPage} />
                <PrivateRoute path="/payment-methods-history" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={PaymentMethodsHistoryPage} />
                <PrivateRoute path="/payment-methods-chart" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor]} component={PaymentMethodsChartPage} />
                <PrivateRoute path="/payment-methods-general-report" roles={[Role.Admin, Role.Collector, Role.Auditor, Role.Supervisor]} component={PaymentMethodsGeneralReportPage} />
                <PrivateRoute path="/departures" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor]} component={DepartureListPage} />
                <PrivateRoute path="/inventory-report-plus" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryReportPlusPage} />
                <PrivateRoute path="/inventory-adjustment-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Auditor]} component={InventoryAdjustmentHistoryPage} />
                <PrivateRoute path="/offer-history" roles={[Role.Admin, Role.Supervisor, Role.Manager, Role.Telesales]} component={OfferReportPage} />
                <PrivateRoute path="/cron-history" roles={[Role.Admin]} component={CronHistoryPage} />
                <PrivateRoute path="/inventory-offer" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={InventoryOfferPage} />
                <PrivateRoute path="/box-report" roles={[Role.Admin, Role.Collector, Role.Auditor, Role.Supervisor]} component={BoxReportPage} />
                <PrivateRoute path="/box-close-report" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={BoxCloseReportPage} />
                <PrivateRoute path="/client-list" roles={[Role.Admin, Role.Manager, Role.Cashier, Role.Collector, Role.Auditor, Role.Telesales]} component={ClientListPage} />
                <PrivateRoute path="/wholesale-client-list" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Telesales]} component={WholesaleClientListPage} />
                <PrivateRoute path="/operators-performance" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={OperatorsPerformancePage} />
                <PrivateRoute path="/cashiers-performance" roles={[Role.Admin, Role.Manager, Role.Collector, Role.Auditor, Role.Supervisor]} component={CashiersPerformancePage} />
                
                
                {/* Activos */}
                <PrivateRoute path="/assets" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={AssetsListPage} />
                <PrivateRoute path="/register-assets" roles={[Role.Admin, Role.Manager, Role.Supervisor]} component={AssetsCreatePage} />
                <PrivateRoute path="/update-assets" roles={[Role.Admin, Role.Manager, Role.Supervisor]} component={AssetsUpdatePage} />
                <PrivateRoute path="/assets-record" roles={[Role.Admin, Role.Supervisor, Role.Manager]} component={AssetsRecordPage} />
                <PrivateRoute path="/assets-dump" roles={[Role.Admin, Role.Supervisor]} component={AssetsDumpListPage} />

                {/* Codigos de autorizacion */}
                <PrivateRoute path="/create-authorization-code" roles={[Role.Admin]} component={AuthorizationCodeCreatePage} />
                <PrivateRoute path="/authorization-codes" roles={[Role.Admin]} component={AuthorizationCodesPage} />
                
                <Redirect from="*" to="/" />
            </Switch>
        </Router>     
    );
}

export default App